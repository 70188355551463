<template>
    <v-card
        class="mx-auto my-5"
        max-width="374"
    >
        <v-img  class="cursor-pointer" :src="video.thumbnail_url"
               @click="$router.push({name: 'dashboard.videos.show', params: {id: video.id}})"
        >

        </v-img>
        <v-card-title class="cursor-pointer align-content-space-around"  style="height: 100px" @click="$router.push({name: 'dashboard.videos.show', params: {id: video.id}})">
            {{video.name}}
        </v-card-title>
<!--        <v-card-text>
            <div class="my-4 subtitle-1" style="height: 64px">
                <v-chip v-for="tag in video.tags"
                        @click="$router.push({query: {tag: tag.name}})"
                        :key="tag.id"
                >
                    {{tag.name}}
                </v-chip>
            </div>
        </v-card-text>-->
        <v-card-actions  >
            <small>{{video.created_at | formattedDate}}</small>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "VideoCard",
        props: ['video'],
        data: function () {
            return {

            }
        },
    }
</script>

<style scoped>

</style>
